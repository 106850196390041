import { Inject, Injectable, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '@cue/api';
import { Store } from '@ngrx/store';
import { AppState } from '../models';
import { loadControllableObjects } from '../actions';

export const CLIENT_ID: InjectionToken<string> = new InjectionToken<string>('CUE_CLIENT_ID');

export interface ControllableObjectUpdateData {
  objectId: string;
  data: any;
}

@Injectable()
export class ControllableObjectsSignalRService {
  // dataFromSignalR$: BehaviorSubject<any> = new BehaviorSubject(null);
  //
  // chatMessageList: string[] = [];
  // chatMessageList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  controllableObjectUpdateData$: BehaviorSubject<ControllableObjectUpdateData> = new BehaviorSubject(null);

  private hubConnection: signalR.HubConnection;

  constructor(
    @Inject(CLIENT_ID) private clientId: string,
    private apiService: ApiService,
    private store: Store<AppState>,
  ) {}

  public startConnection = () => {
    const url = this.apiService.getUrl('/MessageHub');
    console.log('START CONNECTION ON URL: ', url);

    const apiToken = localStorage.getItem('api_token');
    if (apiToken) {
      //TODO: Jakub pohandlit refresh tokenu
      this.hubConnection = new signalR.HubConnectionBuilder().withUrl(url, { accessTokenFactory: () => apiToken }).build();
    } else {
      this.hubConnection = new signalR.HubConnectionBuilder().withUrl(url).build();
    }

    this.hubConnection
      .start()
      .then(() => console.log('SignalR connection started'))
      .catch((err) => console.log('Error while starting SignalR connection: ' + err));
  };

  public addTransferDataListener = () => {
    // this.hubConnection.on('ReceiveMessage', (data, message) => {
    //   console.log('RECEIVED');
    //   console.log('data: ', data);
    //   console.log('message: ', message);
    //   this.chatMessageList.push(data + ' - ' + message);
    //   this.chatMessageList$.next(this.chatMessageList);
    // });

    this.hubConnection.on('ObjectChanged', (data, message) => {
      this.store.dispatch(loadControllableObjects());
      this.controllableObjectUpdateData$.next({
        objectId: data,
        data: JSON.parse(message),
      });
    });
  };

  public sendMessageSignalR = (objectId: string, itemId: string) => {
    console.log('SENDING VIA SIGNALR');
    console.log('objectId: ', objectId);
    console.log('itemId: ', itemId);

    console.log(this.hubConnection.state);
    this.hubConnection.invoke('Object', objectId, itemId).catch((err) => console.error(err));
  };
}

// export function provideSignalRService(options: { factory: () => string }) {
//   return makeEnvironmentProviders([
//     {
//       provide: CLIENT_ID,
//       multi: false,
//       useFactory: options.factory,
//     },
//     ControllableObjectsSignalRService,
//   ]);
// }
