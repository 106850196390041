import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// import { ConfigService } from '@assist/shared/data';

@Injectable({
  providedIn: 'root',
})
export class UnitySignalRService {
  dataFromSignalR$: BehaviorSubject<any> = new BehaviorSubject(null);

  chatMessageList: string[] = [];
  chatMessageList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private hubConnection: signalR.HubConnection;

  constructor() {}

  public startConnection = () => {
    // const url = this.apiService.getUrl('/MessageHub');
    // console.log('START CONNECTION ON URL: ', url);
    //
    // const apiToken = localStorage.getItem('api_token');
    // if (apiToken) {
    //   this.hubConnection = new signalR.HubConnectionBuilder().withUrl(url, { accessTokenFactory: () => apiToken }).build();
    // } else {
    //   this.hubConnection = new signalR.HubConnectionBuilder().withUrl(url).build();
    // }
    //
    // this.hubConnection
    //   .start()
    //   .then(() => console.log('SignalR connection started'))
    //   .catch((err) => console.log('Error while starting SignalR connection: ' + err));
  };

  public addTransferDataListener = () => {
    this.hubConnection.on('ReceiveMessage', (data, message) => {
      console.log('RECEIVED');
      console.log('data: ', data);
      console.log('message: ', message);
      this.chatMessageList.push(data + ' - ' + message);
      this.chatMessageList$.next(this.chatMessageList);
    });
  };

  // public sendMessageSignalR = (data: string) => {
  //   console.log('SENDING VIA SIGNALR');
  //   console.log('data: ', data);
  //   console.log('clientId: ', this.configService.value.clientId);
  //   this.hubConnection.invoke('SendMessage', this.configService.value.clientId || 'Unknown', data).catch((err) => console.error(err));
  // };

  public sendTestDataNormalWay(data: string) {
    // console.log('SENDING VIA STANDARD REQUEST');
    // console.log('data: ', data);
    // const url = '/api/navigation/SignalR';
    //
    // return this.apiService
    //   .call<any, any>({
    //     url: url,
    //     method: 'get',
    //   })
    //   .pipe(
    //     map((r) => {
    //       return true;
    //     }),
    //   );
  }
}
